<script lang="ts" setup>
import { ref, computed, getCurrentInstance } from "vue";
import UsersRelationship from "@/components/iam/UsersRelationship.vue";
import { iamType, addRelationship, removeUserGroupFromUser } from "@/services/api/iam.api";

const props = defineProps({
  groupId: undefined,
});

const refreshKey = ref(0);

const accessSelection = computed(() => {
  return { domain: iamType.UserGroup, id: props.groupId };
});

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function onAddRelationship(selection) {
  console.log("UserGroupUsers onAddRelationship", selection);
  const response = await addRelationship(iamType.UserGroup, props.groupId, selection);
  if (response) {
    if (!response.error) {
      refreshKey.value++;
    } else {
      proxy.$toaster.error("Toewijzen van de gebruikers aan een groep is mislukt! " + response.error);
    }
  }
}

async function onRemoveRelationship(id) {
  console.log("UserGroupUsers onRemoveRelationship", id);
  const response = await removeUserGroupFromUser(id, props.groupId);
  if (response) {
    if (!response.error) {
      refreshKey.value++;
    } else {
      proxy.$toaster.error("Verwijder van de gebruikers aan een groep is mislukt! " + response.error);
    }
  }
}
</script>

<template>
  <v-container fluid pa-0 class="app-iam">
    <users-relationship
      @add-relationship="onAddRelationship"
      allow-remove="true"
      @remove-relationship="onRemoveRelationship"
      :refreshKey="refreshKey"
      :viaAccess="accessSelection"
    ></users-relationship>
  </v-container>
</template>
